/**
* ClassyClosets Fonts =================================================================
* Header Text: Lora
* Sub-Header Text: Montserrat
* Body Text: Open Sans
*/

/* Fonts - Lora */
@font-face {
  font-family: "Lora Bold";
  src: local(Lora-Bold),
    url("/fonts/Lora/static/Lora-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "Lora";
  src: local(Lora-Regular),
    url("/fonts/Lora/static/Lora-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "Lora BoldItalic";
  src: local(Lora-BoldItalic),
    url("/fonts/Lora/static/Lora-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-stretch: normal;
}
@font-face {
  font-family: "Lora Italic";
  src: local(Lora-Italic),
    url("/fonts/Lora/static/Lora-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
}
@font-face {
  font-family: "Lora Medium";
  src: local(Lora-Medium),
    url("/fonts/Lora/static/Lora-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lora SemiBold";
  src: local(Lora-SemiBold),
    url("/fonts/Lora/static/Lora-SemiBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "Lora SemiBoldItalic";
  src: local(Lora-SemiBoldItalic),
    url("/fonts/Lora/static/Lora-SemiBoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-stretch: normal;
}

/* Fonts - Montserrat */
@font-face {
  font-family: "Montserrat";
  src: local(Montserrat-Bold),
    url("/fonts/Montserrat/Montserrat-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat SemiBold";
  src: local(Montserrat-SemiBold),
    url("/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat Medium";
  src: local(Montserrat-Medium),
    url("/fonts/Montserrat/Montserrat-Medium.woff") format("woff");
  font-weight: 500;
  font-style: medium;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: local(Montserrat-Regular),
    url("/fonts/Montserrat/Montserrat-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

/* @font-face {
  font-family: "Montserrat";
  src: local(Montserrat-Italic),
    url("/fonts/Montserrat/Montserrat-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
} */

/* Fonts - Open Sans */

@font-face {
  font-family: "Open Sans";
  src: local(OpenSans-Blod),
    url("/fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "OpenSans SemiBold";
  src: local(Montserrat-SemiBold),
    url("/fonts/OpenSans/OpenSans-SemiBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "OpenSans Medium";
  src: local(OpenSans-Medium),
    url("/fonts/OpenSans/OpenSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: medium;
  font-stretch: normal;
}

@font-face {
  font-family: "Open Sans";
  src: local(OpenSans-Regular),
    url("/fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

/* @font-face {
  font-family: "Open Sans";
  src: local(OpenSans-Italic),
    url("/fonts/OpenSans/OpenSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
} */

/* Fonts - Helvetica */

@font-face {
  font-family: "Helvetica";
  src: local(Helvetica),
    url("/fonts/Helvetica/Helvetica.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

/* @font-face {
  font-family: "Helvetica";
  src: local(Helvetica-Bold),
    url("/fonts/Helvetica/Helvetica-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
} */

@font-face {
  font-family: "Helvetica Light";
  src: local(Helvetica-Light),
    url("/fonts/Helvetica/Helvetica-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

/* Fonts - Roboto */

@font-face {
  font-family: "Roboto Black";
  src: local(Roboto-Black),
    url("/fonts/Roboto/Roboto-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Roboto BlackItalic";
  src: local(Roboto-BlackItalic),
    url("/fonts/Roboto/Roboto-BlackItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Roboto Bold";
  src: local(Roboto-Bold),
    url("/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Roboto BoldItalic";
  src: local(Roboto-BoldItalic),
    url("/fonts/Roboto/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Roboto Italic";
  src: local(Roboto-Italic),
    url("/fonts/Roboto/Roboto-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Roboto Light";
  src: local(Roboto-Light),
    url("/fonts/Roboto/Roboto-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Roboto LightItalic";
  src: local(Roboto-LightItalic),
    url("/fonts/Roboto/Roboto-LightItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Roboto Medium";
  src: local(Roboto-Medium),
    url("/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Roboto MediumItalic";
  src: local(Roboto-MediumItalic),
    url("/fonts/Roboto/Roboto-MediumItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Roboto Regular";
  src: local(Roboto-Regular),
    url("/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Roboto Thin";
  src: local(Roboto-Thin),
    url("/fonts/Roboto/Roboto-Thin.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Roboto ThinItalic";
  src: local(Roboto-ThinItalic),
    url("/fonts/Roboto/Roboto-ThinItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Saulifriend";
  src: local(Saulifriend),
    url("/fonts/Saulifriend/Saulifriend.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Lato";
  src: local(Lato-Bold),
    url("/fonts/Lato/Lato-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

