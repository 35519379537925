/* Desktop menu visibility overrides */
.desktop-menu-always-visible .menu-container {
  /* Always show the menu on desktop */
  opacity: 1 !important;
  visibility: visible !important;
  transform: none !important;
  pointer-events: auto !important;
}

/* Ensure we don't affect mobile devices */
@media only screen and (max-width: 768px) {
  .desktop-menu-always-visible .menu-container {
    /* Reset to default behavior on mobile */
    opacity: initial;
    visibility: initial;
    transform: initial;
    pointer-events: initial;
  }
} 